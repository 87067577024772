import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      createLPNValid: false,
      numofLPN: "",
      primaryLabelSize: EncryptUtility.localStorageDecrypt("primaryLabelSize"),
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      showPrimary: true,
      printDisable: true,
      printerConfigured: false,
      numberRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    if (this.primaryLabelSize !== null && this.primaryLabelSize !== undefined) {
      this.printDisable = false;
      this.printerConfigured = false;
    } else {
      this.printDisable = true;
      this.printerConfigured = true;
      let Alert = {
        type: "error",
        isShow: true,
        message: "Receiving/Inventory Printer is not configured, please configure printer",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    }
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.createLPNValid = false;
      this.numofLPN = "";
      this.$refs.createSpoolLPNForm.resetValidation();
    },
    //Create Label based on number of Labels entered
    createLPN() {
      if (EncryptUtility.localStorageDecrypt("primaryLabelSize") !== null && EncryptUtility.localStorageDecrypt("primaryLabelSize") !== undefined) {
        if (this.$refs.createSpoolLPNForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let createLPNLabel = {
            num: parseInt(this.numofLPN),
            user_key: parseInt(this.userId),
            lbl_size: this.primaryLabelSize,
          };
          this.axios
            .post("/mm/lpn_create", createLPNLabel)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                let responsedata = [];
                responsedata = JSON.parse(response.data.body.message);
                PrinterDetails.printLabelDataArray(responsedata, this.primaryName);
                this.resetFunction();
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Receiving/Inventory Printer is not configured, please configure printer",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //To check whether input value is number or not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //To capture printer details once saved
    printerDetails(labelPrinter) {
      if (!labelPrinter) {
        this.printDisable = false;
        this.printerConfigured = false;
      } else {
        this.printDisable = true;
        this.printerConfigured = true;
      }
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
